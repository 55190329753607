import {
  CREATE_PAYMENT,
  PAYMENT_RESPONSE,
  PAYMENT_DETAILS,
  PAYMENT_DETAILS_RESPONSE,
  SUCCESS_PAYMENT,
  UPDATE_MINUTES,
} from './payment.types'

export const makepayment = (paydata) => ({
  type: CREATE_PAYMENT,
  payload: paydata,
})

export const paymentResponse = (res) => ({
  type: PAYMENT_RESPONSE,
  payload: res,
})

export const paymentDetails = (id) => ({
  type: PAYMENT_DETAILS,
  payload: id,
})

export const paymentDetailsResponse = (res) => ({
  type: PAYMENT_DETAILS_RESPONSE,
  payload: res,
})
export const successPayment = (status) => ({
  type: SUCCESS_PAYMENT,
  payload: status,
})

export const updateMinutes = (value) => ({
  type: UPDATE_MINUTES,
  payload: value,
})
