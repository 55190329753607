/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const LOGIN = 'LOGIN'
export const LOG_OUT = 'LOGIN_OUT'
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const LOGGED = 'LOGGED'
export const VERIFY_PIN = 'VERIFY_PIN'
export const VERIFY_PIN_RESPONSE = 'VERIFY_PIN_RESPONSE'
export const SMS_LOGIN = 'SMS_LOGIN'
export const SMS_LOGIN_RESPONSE = 'SMS_LOGIN_RESPONSE'
