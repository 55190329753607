import { combineReducers } from 'redux'
import loginReducer from './Login/login.reducer'
import paymentReducer from './Payment/payment.reducer'

/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */

const rootReducer = combineReducers({
  login: loginReducer,
  payment: paymentReducer,
})

export default rootReducer
