import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
// import { ajax } from 'Utils'
// import { ajax as chatAjax } from 'Utils'
import { LOGIN, REQUEST_CANCEL, LOG_OUT, VERIFY_PIN, SMS_LOGIN } from './login.types'
import { loginResponse, checkPinResponse, smsLoginResponse } from './login.actions'
import environment from 'environments/environment'
const baseUrl = environment.baseUrl;

const loginEpic = {}

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
loginEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/trillium-patient-service/v1/patient/login`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.logOut = (action$) =>
  action$.pipe(
    ofType(LOG_OUT),
    switchMap(() =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/trillium-patient-service/v1/patient/logout`,
        method: 'POST',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.verifyPin = (action$) =>
  action$.pipe(
    ofType(VERIFY_PIN),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/trillium-patient-service/v1/patient/verify/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => checkPinResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.smsLogin = (action$) =>
  action$.pipe(
    ofType(SMS_LOGIN),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        // url: `/patient-portal-api/patient/login`,
        url: `${baseUrl}/trillium-patient-service/v1/patient/login`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => smsLoginResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default loginEpic
