import {
  LOGIN,
  LOGIN_RESPONSE,
  LOGGED,
  LOG_OUT,
  VERIFY_PIN,
  VERIFY_PIN_RESPONSE,
  SMS_LOGIN,
  SMS_LOGIN_RESPONSE,
} from './login.types'

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * clickLogin-Action w.r.t LOGIN(type) in the redux
 * @description - Action used to call the login API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const clickLogin = (logData) => ({
  type: LOGIN,
  payload: logData,
})
/**
 * loginResponse - Action w.r.t LOGIN_RESPONSE(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const loginResponse = (user) => ({
  type: LOGIN_RESPONSE,
  payload: user,
})
export const loggedStatus = (status) => ({
  type: LOGGED,
  payload: status,
})
export const logOut = () => ({
  type: LOG_OUT,
  payload: null,
})

export const checkPin = (pinData) => ({
  type: VERIFY_PIN,
  payload: pinData,
})

export const checkPinResponse = (pinResponse) => ({
  type: VERIFY_PIN_RESPONSE,
  payload: pinResponse,
})

export const smsLogin = (logData) => ({
  type: SMS_LOGIN,
  payload: logData,
})

export const smsLoginResponse = (user) => ({
  type: SMS_LOGIN_RESPONSE,
  payload: user,
})
